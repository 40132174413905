/* Lato */
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf')
    format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf')
    format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf')
    format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf')
    format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf')
    format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-Italic.ttf')
    format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-LightItalic.ttf')
    format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraLight.ttf')
    format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf')
    format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf')
    format('truetype');
  font-weight: 100;
  font-style: italic;
}
